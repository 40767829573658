import { CurrencyPipe, DecimalPipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from '@angular/material';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';


@Injectable()
export class ConfirmationDialogService {

    title = 'angular-confirmation-dialog';

    constructor(public dialog: MatDialog, private currencyPipe:CurrencyPipe) {}


    openDialog(){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: "Voce quer alterar esse registro?"
      });

      /*dialogRef.afterClosed().subscribe(result => {
        if(result) {
          console.log('Sim clicked');
          console.log(result);
          // DO SOMETHING
          return result
        }
      });*/
      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;

    }

    confirmarDialog(){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: "Todos os dados informados na coleta estão corretos?"
      });

      /*dialogRef.afterClosed().subscribe(result => {
        if(result) {
          console.log('Sim clicked');
          console.log(result);
          // DO SOMETHING
          return result
        }
      });*/
      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;

    }

    confirmarDialogEdicao(){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: "Todos os dados informados na alteração da coleta estão corretos?"
      });

      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;

    }

    confirmarDialogAmostraRecebida(){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: "Amostra já recebida pelo laboratório, deseja continuar a Edição?"
      });

      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;

    }

    confirmarDialogLiberacao(text: string){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: "Deseja liberar o laudo desta amostra ("+text +")? "
      });
      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;
    }


    envioConsultarTodosRenach(text: string){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        // data: "Deseja liberar o laudo desta amostra ("+text +")? \n Obs. A nota fiscal será emitida no valor de: "+ this.currencyPipe.transform(text,"BRL")
        data: "Deseja liberar o laudo desta amostra ("+text +")? \n Obs. A nota fiscal também será emitida"

      });
      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;
    }

    confirmarDialogLiberacaoLote(text: string){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: "Deseja liberar o laudo destas amostras?"
      });
      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;
    }


    confirmarDialogNotaEmissao(text: string){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: "Deseja emitir a nota fiscal de valor  "+ this.currencyPipe.transform(text,"BRL") +" ?"
      });
      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;
    }


    confirmarDialogNotaCancelamento(){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: "Deseja cancelar esta nota fiscal?"
      });
      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;
    }

    confirmarDialogRenachColetaExclusao(){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: "Deseja remover a coleta do Renach"
      });
      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;
    }

    confirmarDialogRenachExclusao(){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: "Deseja remover o laudo do Renach"
      });
      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;
    }

    confirmarDialogRenachEnvio(){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: "Os dados informados estão corretos para remeter ao Renach?"
      });
      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;
    }

    confirmarDialogLogistica(){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: "Deseja notificar o não recebimento dessa amostra?"
      });
      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;
    }

    confirmarDialogRecebimento(){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: "Todos os dados informados no recebimento estão corretos?"
      });
      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;
    }

    confirmarDialogDescancelamentoAmostra(){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '450px',
        data: "Deseja descancelar essa amostra?"
      });
      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;
    }

    alertDialog(text: string){
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: "Voce quer alterar esse registro?"
      });

      /*dialogRef.afterClosed().subscribe(result => {
        if(result) {
          console.log('Sim clicked');
          console.log(result);
          // DO SOMETHING
          return result
        }
      });*/
      dialogRef.componentInstance.changeRef.markForCheck();
      return dialogRef.componentInstance.event;

    }
}
