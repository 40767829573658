import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { APP_BASE_HREF, DatePipe, registerLocaleData, HashLocationStrategy, LocationStrategy, CurrencyPipe } from '@angular/common';
import { HttpModule } from '@angular/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Error404Component } from './components/error404/error404.component';
import { ResetPasswordDialogComponent } from './core/shared/reset-password-dialog/reset-password-dialog.component';
import { MessageDialogComponent } from './core/shared/message-dialog/message-dialog.component';
import { ConfirmationDialogComponent } from './core/shared/confirmation-dialog/confirmation-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipeModule } from './core/pipe/pipe.module';
import { RouterModule } from '@angular/router';
import { CustomMaterialModule } from './core/modules/material.module';
import { NotificationService } from './core/notification/notification-service.service';
import { AuthGuard } from './_auth/auth.guard';
import { AuthService } from './_auth/auth.service';
import { ConfirmationDialogService } from './core/shared/confirmation-dialog/confirmation-dialog.service';
import { MessageDialogService } from './core/shared/message-dialog/message-dialog.service';
import { ResetPasswordDialogService } from './core/shared/reset-password-dialog/reset-password-dialog.service';
import { AppConfig } from './app.config';
import { SessionStorageControle } from './core/session-storage-controle';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TextMaskModule } from 'angular2-text-mask';
import { ToastrModule } from 'ngx-toastr';
import { FlexLayoutModule } from "@angular/flex-layout";
import { MAT_DATE_LOCALE } from '@angular/material';
import { OAuthService } from './services/oauth.service';
import { MenuService } from './services/menu.service';
import { LayoutsModule } from './components/layouts/layouts.module';
import localePt from '@angular/common/locales/pt';
import { DirectivesModule } from './core/directives/directives.module';
import { ChangePasswordDialogComponent } from './core/shared/change-password-dialog/change-password-dialog.component';
import { ChangePasswordDialogService } from './core/shared/change-password-dialog/change-password-dialog.service';
import { AnimationsModule } from './core/animations/animation.module';
import { PushDialogComponent } from './core/shared/push-dialog/push-dialog.component';
import { PushDialogService } from './core/shared/push-dialog/push-dialog.service';
import { NotificationDialogComponent } from './core/shared/notification-dialog/notification-dialog.component';
import { NgxMaskModule } from 'ngx-mask';
import { MatSelectFilterModule } from 'mat-select-filter';

registerLocaleData(localePt, 'pt');

@NgModule({
  declarations: [

    AppComponent,

    Error404Component,

    ConfirmationDialogComponent,

    MessageDialogComponent,

    ResetPasswordDialogComponent,

    ChangePasswordDialogComponent,

    PushDialogComponent,

    NotificationDialogComponent,


  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    PipeModule,
    HttpModule,
    RouterModule,
    TextMaskModule,
    CustomMaterialModule,
    FlexLayoutModule,
    MatSelectFilterModule,
    LayoutsModule,
    DirectivesModule,
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot(
      {
        positionClass: 'toast-bottom-right',
        preventDuplicates: true
      }), // ToastrModule added


    AppRoutingModule,
    AnimationsModule,
  ],
  entryComponents:[
    PushDialogComponent,
    MessageDialogComponent,
    ConfirmationDialogComponent,
    NotificationDialogComponent,
    ResetPasswordDialogComponent,
    ChangePasswordDialogComponent,
  ],

  providers: [
    OAuthService,
    AuthGuard,
    AuthService,
    MenuService,
    PushDialogService,
    NotificationService,
    MessageDialogService,
    ConfirmationDialogService,
    ResetPasswordDialogService,
    ChangePasswordDialogService,
    SessionStorageControle,
    CurrencyPipe,
    AppConfig,
    {

    provide: APP_INITIALIZER,
    useFactory: (config: AppConfig) => () => config.load(),
    deps: [AppConfig],
    multi: true
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'pt-br'
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt' },
    {
      provide: APP_BASE_HREF,
      useValue : '/'
    },
    {
      provide: LocationStrategy, useClass: HashLocationStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
