import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule, MatSidenavModule, MatButtonModule, MatCardModule, MatInputModule, MatDialogModule, MatTableModule, MatMenuModule, MatIconModule, MatProgressSpinnerModule, MatListModule, MatGridListModule, MatPaginatorModule, MatSortModule, MatStepperModule, MatAutocompleteModule, MatOptionModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatRadioModule, MatButtonToggleModule, MatTabsModule, MatTabNav, MatCheckboxModule, MatChipsModule, MatProgressBarModule, MatTooltipModule, MatBadgeModule, MatDividerModule, MatSlideToggleModule } from '@angular/material';
import { MatCarouselModule } from '@ngmodule/material-carousel';

  @NgModule({
    imports: [
    CommonModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatStepperModule,
    MatDividerModule,
    MatSidenavModule,
    MatListModule,
    MatGridListModule,
    MatToolbarModule,
    MatButtonModule, 
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatRadioModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSortModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatSelectModule,
    MatDatepickerModule,       
    MatNativeDateModule,        
    MatCheckboxModule,
    MatChipsModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatSlideToggleModule,
    MatCarouselModule.forRoot(),
    ],
    exports: [
    CommonModule, 
    MatTabsModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatStepperModule,
    MatDividerModule,
    MatSidenavModule,
    MatListModule,
    MatGridListModule,
    MatToolbarModule,
    MatButtonModule, 
    MatCardModule,
    MatInputModule,
    MatDialogModule,
    MatTableModule,
    MatMenuModule,
    MatRadioModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSortModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatSelectModule,
    MatDatepickerModule,       
    MatNativeDateModule, 
    MatCheckboxModule,
    MatChipsModule,
    MatProgressBarModule,
    MatBadgeModule,
    MatSlideToggleModule,
    MatCarouselModule,   
     ],
  })
  export class CustomMaterialModule { }